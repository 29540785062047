<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect
      x="0.75"
      y="0.75"
      width="22.5"
      height="22.5"
      rx="11.25"
      stroke="#101840"
      stroke-width="1.5"
    />
    <path
      d="M12.0688 14.35C11.5198 14.35 11.0646 13.8948 11.0646 13.3458V13.0646C11.0646 11.5113 12.2027 10.7481 12.6312 10.4535C13.1267 10.1187 13.2873 9.89109 13.2873 9.54294C13.2873 8.87342 12.7384 8.32437 12.0688 8.32437C11.3993 8.32437 10.8504 8.87342 10.8504 9.54294C10.8504 10.0919 10.3951 10.5472 9.84608 10.5472C9.29707 10.5472 8.8418 10.0919 8.8418 9.54294C8.8418 7.76202 10.2879 6.31581 12.0688 6.31581C13.8498 6.31581 15.2959 7.76202 15.2959 9.54294C15.2959 11.0694 14.1711 11.8327 13.756 12.1139C13.2338 12.462 13.0731 12.6897 13.0731 13.0646V13.3458C13.0731 13.9082 12.6178 14.35 12.0688 14.35Z"
      fill="#101840"
    />
    <path
      d="M12.0707 17.6842C11.5083 17.6842 11.0664 17.2289 11.0664 16.6799C11.0664 16.1309 11.5217 15.6757 12.0707 15.6757C12.6197 15.6757 13.075 16.1309 13.075 16.6799C13.075 17.2289 12.6331 17.6842 12.0707 17.6842Z"
      fill="#101840"
    />
  </svg>
</template>
