<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 24 24"
    fill="none"
    class="p-2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2.21924H8C4.5 2.21924 3 4.21924 3 7.21924V17.2192C3 20.2192 4.5 22.2192 8 22.2192H16C19.5 22.2192 21 20.2192 21 17.2192V7.21924C21 4.21924 19.5 2.21924 16 2.21924ZM8 12.4692H12C12.41 12.4692 12.75 12.8092 12.75 13.2192C12.75 13.6292 12.41 13.9692 12 13.9692H8C7.59 13.9692 7.25 13.6292 7.25 13.2192C7.25 12.8092 7.59 12.4692 8 12.4692ZM16 17.9692H8C7.59 17.9692 7.25 17.6292 7.25 17.2192C7.25 16.8092 7.59 16.4692 8 16.4692H16C16.41 16.4692 16.75 16.8092 16.75 17.2192C16.75 17.6292 16.41 17.9692 16 17.9692ZM18.5 9.46924H16.5C14.98 9.46924 13.75 8.23924 13.75 6.71924V4.71924C13.75 4.30924 14.09 3.96924 14.5 3.96924C14.91 3.96924 15.25 4.30924 15.25 4.71924V6.71924C15.25 7.40924 15.81 7.96924 16.5 7.96924H18.5C18.91 7.96924 19.25 8.30924 19.25 8.71924C19.25 9.12924 18.91 9.46924 18.5 9.46924Z"
      fill="#DD5928"
    />
  </svg>
</template>
