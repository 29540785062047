<template>
  <div class="settings" style="border: 1px solid #e6e8f0">
    <div class="relative flex items-center cursor-pointer">
      <a
        href="javascript:void(0)"
        @click="routeUrl()"
        class="flex items-center"
      >
        <div class="mt-4 -ml-25">
          <svg
            width="41"
            height="20"
            viewBox="0 0 41 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40.4" height="5.77143" rx="2.88571" fill="#DD5928" />
            <rect
              y="11.543"
              width="40.4"
              height="5.77143"
              rx="2.88571"
              fill="#DD5928"
            />
            <rect
              y="23.0859"
              width="40.4"
              height="5.77143"
              rx="2.88571"
              fill="#DD5928"
            />
          </svg>
        </div>
        <p
          class="
            mt-4
            text-[27px]
            N700
            font-bold
            sm:hidden
            md:hidden
            lg:block
            xl:block
            mr-24
          "
          v-if="accessStore.showAll"
        >
          AmaliTech
        </p>
      </a>
      <span
        class="
          absolute
          flex
          items-center
          w-6
          h-6
          px-2
          py-0
          rounded-full
          shadow-md
          BG0
          top-10
          -right-5
        "
        @click="accessStore.showAll = !accessStore.showAll"
      >
        <ArrowLeftIcon v-if="accessStore.showAll" />
        <ArrowRightIcon v-else />
      </span>
    </div>
    <HorizontalLine backgroundColor="N300" :margin="'my-4'" />
    <div class="flex flex-col justify-between">
      <div class="N700">
        <p
          class="
            pt-5
            pl-3
            text-base
            font-semibold
            truncate
            N700
            sm:hidden
            md:hidden
            lg:block
            xl:block
            w-52
          "
          v-if="accessStore.showAll"
        >
          Performance Management
        </p>
        <NavigationComponent
          :item="{ label: 'Home', route: '/performance-service' }"
          @mouseover="toggleOnDashboad(true)"
          @mouseout="toggleOnDashboad(false)"
          data-cy="dashboard"
        >
          <HomeIcon
            :color="
              onDashboard || $route.path.startsWith('/performance-service')
                ? '#DD5928'
                : '#8F95B2'
            "
          />
        </NavigationComponent>
        <NavigationComponent
          :item="{
            label: 'My Assessments',
            route: '/assessments/'
          }"
          @mouseover="toggleOnAssessments(true)"
          @mouseout="toggleOnAssessments(false)"
          data-cy="assessments"
        >
          <AssessmentManagerIcon
            :color="
              onAssessments || $route.path.startsWith('/assessments/')
                ? '#DD5928'
                : '#8F95B2'
            "
          />
        </NavigationComponent>

        <div class="-mt-1" v-if="showPageSettings">
          <HorizontalLine backgroundColor="N500" class="-mb-4" />
          <p
            class="
              text-base
              font-semibold
              truncate
              N700
              sm:hidden
              md:hidden
              lg:block
              xl:block
              w-52
            "
            v-if="accessStore.showAll"
            :class="onTitle"
          >
            Admin
          </p>
          <NavigationComponent
            :item="{
              label: 'Configurations',
              route: '/configurations'
            }"
            @mouseover="toggleOnSettings(true)"
            @mouseout="toggleOnSettings(false)"
            data-cy="settings"
            v-if="showPageSettings"
            class="-my-4"
          >
            <ConfigurationIcon
              :color="
                onSettings || $route.path.startsWith('/configurations')
                  ? '#DD5928'
                  : '#8F95B2'
              "
            />
          </NavigationComponent>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ConfigurationIcon from "@/apps/performance-assessment/assets/ConfigurationIcon.vue";
import AssessmentManagerIcon from "@/apps/performance-assessment/assets/AssessmentManagerIcon.vue";
import HomeIcon from "@/apps/performance-assessment/assets/HomeIcon.vue";
import NavigationComponent from "@/apps/performance-assessment/components/NavigationComponent.vue";
import { computed, ref } from "vue";
import HorizontalLine from "@/components/HorizontalLine.vue";
import { getToken } from "@/services/auth";
import config from "@/config/config";
import { usePerformanceAccess } from "@/store/performance_access_store";
import ArrowLeftIcon from "@/assets/ArrowLeftIcon.vue";
import ArrowRightIcon from "@/assets/ArrowRightIcon.vue";

const accessStore = usePerformanceAccess();
const showPageAssessment = computed(() => {
  return accessStore.permissions.some(
    (permission) =>
      permission.permission_name === "Assessment Manager" &&
      (permission.edit || permission.view)
  );
});
const showPageDirect = computed(() => {
  return accessStore.permissions.some(
    (permission) =>
      permission.permission_name === "Direct Response" &&
      (permission.edit || permission.view)
  );
});
const showPagePeerAssignment = computed(() => {
  return accessStore.permissions.some(
    (permission) =>
      permission.permission_name === "Peer Assignment" &&
      (permission.edit || permission.view)
  );
});
const showPageManagerAssessment = computed(() => {
  return accessStore.permissions.some(
    (permission) =>
      permission.permission_name === "Manager Assessment" &&
      (permission.edit || permission.view)
  );
});
const showPageSettings = computed(() => {
  return accessStore.permissions.some(
    (permission) =>
      permission.permission_name === "Settings" &&
      (permission.edit || permission.view)
  );
});

const emits = defineEmits(["appSelected"]);

const onDashboard = ref(false);
const onAssessmentManager = ref(false);
const onDirectReportManager = ref(false);
const ontManagerAssessment = ref(false);
const onPeerAssessment = ref(false);
const onPeerAssignment = ref(false);
const onSelfAssessment = ref(false);
const onSettings = ref(false);
const onTitle = ref(false);
const onAssessments = ref(false);

const toggleOnDashboad = (status: boolean) => {
  onDashboard.value = status;
};
const toggleOnAssessmentManager = (status: boolean) => {
  onAssessmentManager.value = status;
};
const toggleOnPeerAssignment = (status: boolean) => {
  onPeerAssignment.value = status;
};
const toggleOnAssessments = (status: boolean) => {
  onAssessments.value = status;
};
const toggleOnSettings = (status: boolean) => {
  onSettings.value = status;
};
const store = usePerformanceAccess();

const routeUrl = () => {
  const token: string | null = getToken();
  if (token) {
    window.location.href = `${config.mainPageLink}`;
  }
};

defineProps({
  homeConditions: {
    type: Boolean
  }
});
</script>
<style scoped>
.settings {
  height: 100vh;
  font-weight: 400;
  font-size: 1rem;
  line-height: 12px;
  padding: 0 1rem;
  color: #474d66;
  background-color: white;
  overflow-y: scroll;
  -ms-overflow-style: none;
  z-index: 100;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.settings::-webkit-scrollbar {
  display: none;
}
ul {
  margin: 19px 0;
}
li {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.H700 {
  z-index: 2;
}
@media screen and (min-width: 670px) and (max-width: 768px) {
  .menu {
    padding: 10px;
  }
}
@media screen and (max-width: 670px) {
  .menu {
    position: relative;
    top: 0;
    height: 40px;
    left: 5px;
    border: 1px solid #d8dae5;
    border-radius: 8px;
  }
  .menu_view {
    width: auto;
  }
}
</style>
