import performanceAssessmentRoutes from "@/apps/performance-assessment/router/performanceAssessmentRoutes";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageNotFound from "../apps/authentication/components/PageNotFound.vue";
import { fetchToken, getToken, isLoggedIn, setToken } from "../services/auth";
import { useStore } from "@/store";

const route: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    alias: "/login",
    component: () => {
      window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}login`;
      return null; // Adjust the import path accordingly
    },
    meta: { auth: false }
  },
  {
    path: "/sending-email",
    name: "SendingEmail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/SendingEmail.vue"
      )
  },
  {
    path: "/performance-service",
    alias: "/",
    name: "performanceService",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "signin" */ "../apps/performance-assessment/views/PerformanceDashboard.vue"
      )
    // component:
  },
  {
    path: "/user-profile",
    name: "user-profile",
    meta: { auth: true },
    component: PageNotFound
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { auth: true }
  }

  /** End of Auth routes */
];

const env = process.env;

export const routes = route.concat(performanceAssessmentRoutes);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, _from, next) => {
  if (getToken()) return next();

  const response = await fetchToken();

  if (response.app_token) {
    const appToken = response.app_token;
    const loginHint = response.login_hint as string;
    setToken(appToken as string);
    useStore()?.setLoginHint(loginHint);
    next();
  } else if (to.meta.auth && !isLoggedIn()) {
    const redirect = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${env.VUE_APP_SSO}/v2/login?redirect-to=${redirect}`; // to change this to new dashboard after ever service complete new implementation
  } else {
    next();
  }
});
export default router;
