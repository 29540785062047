<template>
  <div
    class="
      absolute
      top-0
      bottom-0
      left-0
      right-0
      z-30
      flex flex-col
      items-center
      justify-center
      bg-white
      rounded-md
      bg-opacity-95
    "
  >
    <img src="../../assets/logo-loader.gif" alt="loader" class="loader" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";

const showError = ref(false);
const loadingText = ref("Fetching data... This won't take long");

const displayText = computed(() => {
  if (showError.value) {
    return "We're sorry but it seems we're having trouble loading your content right now.";
  } else {
    return loadingText.value;
  }
});

onMounted(() => {
  setTimeout(() => {
    loadingText.value =
      "It looks like there's a hiccup in the loading process. <center><p>We appreciate your patience as we resolve this.</p></center>";
  }, 6000);

  setTimeout(() => {
    showError.value = true;
  }, 15000);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
