<template>
  <RouterLink :to="`${item?.route}`" class="">
    <div
      class="
        flex
        items-center
        gap-4
        rounded-lg
        cursor-pointer
        my-4
        py-3
        px-4
        hover:bg-[#FFF0EA]
        w-auto
      "
      :class="$route.path.startsWith(`${item?.route}`) ? 'bg-[#FFF0EA]' : ''"
    >
      <div :title="item?.label" v-if="!showSideNavList.showAll">
        <slot></slot>
      </div>
      <div v-else>
        <slot></slot>
      </div>
      <span
        class="
          text-base
          sm:hidden
          md:hidden
          lg:block
          xl:block
          whitespace-nowrap
          truncate
          w-52
        "
        :data-cy="dataCy"
        :class="$route.path.startsWith(`${item?.route}`) ? 'O400' : ''"
        v-if="showSideNavList.showAll"
        >{{ item?.label }}</span
      >
    </div>
  </RouterLink>
</template>
<script setup lang="ts">
import { PropType } from "vue";
import { useRoute } from "vue-router";
import { usePerformanceAccess } from "@/store/performance_access_store";

const $route = useRoute();
const showSideNavList = usePerformanceAccess();
defineProps({
  item: {
    type: Object as PropType<{ label: string; route: string }>
  },
  dataCy: {
    type: String,
    default: ""
  }
});
</script>
