import {
  Question,
  Questionnaire,
  Generator,
  QuestionnaireType,
  QuestionType
} from "@/helpers/classes";
import { defineStore } from "pinia";

// types of the variables and objects to be kept in state

export interface CompetencyObjectType {
  id: number;
  competency_name: string;
}

// store to handle data to be used with the questionnaires
export const questionnaireList = defineStore({
  id: "questionnaire_list",
  state: () => ({
    userName: "",
    userPosition: "",
    userProfile: "",
    questionnaires: {} as { [key: string]: QuestionnaireType },
    currentquestionnaireId: "" as string,
    activeSelectedCompetency: 0 as number,
    listOfSelected: [] as Array<CompetencyObjectType>, //Keep the list of the selected competencies (used for populating competency-based questions at the store for assessment)
    editQuestionnaireMode: false as boolean, //Toggle between questionnaire edit state
    previewState: false //storing the preview state for a given questionnaire's set of questions
  }),
  getters: {},
  actions: {
    addQuestionnaire() {
      const newQuestionnaire = new Questionnaire();
      // generate new int questionnaire id for the new questionnaire
      let newId = Generator.randomNumber(1, 1000);
      while (Object.keys(this.questionnaires).includes(String(newId))) {
        newId = Generator.randomNumber(1, 1000);
      }
      const newQuestionnaireId = String(newId);

      this.questionnaires[newQuestionnaireId] = newQuestionnaire;
      this.currentquestionnaireId = newQuestionnaireId;
      return newQuestionnaireId;
    },
    addQuestion(questionnaire_id: string, question: Question) {
      if (this.questionnaires[questionnaire_id]) {
        // add each new question instance created to the questions array of the currently active form/questionnaire
        if (this.activeSelectedCompetency) {
          // filter through the competencies list and find the id for the actively seleted competency
          // const foundComptency = this.listOfCompetencies.find(item => item.id === this.activeSelectedCompetency);
          question.competency_id = this.activeSelectedCompetency;
        }
        // Add questions to the questions object which contains arrays within the questionnaire class
        if (
          !Object.keys(
            this.questionnaires[questionnaire_id].questions
          ).includes(String(this.activeSelectedCompetency))
        ) {
          this.questionnaires[questionnaire_id].questions[
            this.activeSelectedCompetency
          ] = [];
          this.questionnaires[questionnaire_id].questions[
            this.activeSelectedCompetency
          ].push(question);
        } else {
          this.questionnaires[questionnaire_id].questions[
            this.activeSelectedCompetency
          ].push(question);
        }
      } else {
        return `Sorry the questionnaire with ID: ${questionnaire_id} does not exist`;
      }
    },
    deleteQuestion(questionId: string) {
      if (this.currentquestionnaireId) {
        const foundIndex = this.questionnaires[
          this.currentquestionnaireId
        ].questions[this.activeSelectedCompetency].findIndex(
          (question: { question_id: string }) =>
            question.question_id === questionId
        );
        if (foundIndex >= 0) {
          this.questionnaires[this.currentquestionnaireId].questions[
            this.activeSelectedCompetency
          ].splice(foundIndex, 1);
          return questionId;
        } else {
          return "";
        }
      }
    },
    duplicateQuestion(questionId: string) {
      const findQuestionSourceIndex = this.questionnaires[
        this.currentquestionnaireId
      ].questions[this.activeSelectedCompetency].findIndex(
        (question: QuestionType) => question.question_id === questionId
      );
      const duplicateSourceQuestion = JSON.parse(
        JSON.stringify(
          this.questionnaires[this.currentquestionnaireId].questions[
            this.activeSelectedCompetency
          ][findQuestionSourceIndex]
        )
      );
      if (this.editQuestionnaireMode) {
        const findQuestion = this.questionnaires[
          this.currentquestionnaireId
        ].questions[this.activeSelectedCompetency].find(
          (question: QuestionType) => question.question_id === questionId
        );
        const newQuestionId = `${"DUPLICATE"}-${
          findQuestion?.id
        }-${Generator.randomNumber(1000, 10000)}`;
        delete duplicateSourceQuestion.id;
        delete duplicateSourceQuestion.deleted;
        delete duplicateSourceQuestion.updated_by;
        duplicateSourceQuestion.question_id = newQuestionId;
      } else {
        const prevQuestionDate = questionId.split("-");
        const newQuestionId = `${"DUPLICATE"}-${prevQuestionDate
          .slice(1, 4)
          .join("-")}-${Generator.randomNumber(1000, 10000)}`;
        duplicateSourceQuestion.question_id = newQuestionId;
      }
      // Duplicate the question in the questions array with the new question duplicate object having a different ID
      this.questionnaires[this.currentquestionnaireId].questions[
        this.activeSelectedCompetency
      ].splice(findQuestionSourceIndex + 1, 0, duplicateSourceQuestion);

      const foundIndex = this.questionnaires[
        this.currentquestionnaireId
      ].questions[this.activeSelectedCompetency].findIndex(
        (question: { question_id: string }) =>
          question.question_id === duplicateSourceQuestion.question_id
      );
      if (foundIndex) {
        return duplicateSourceQuestion.question_id;
      } else {
        return null;
      }
    },
    addToSelectedCompetencies(selectedCompetency: CompetencyObjectType) {
      const listOfIDs = this.listOfSelected.map((item) => String(item.id));
      if (!listOfIDs.includes(String(selectedCompetency.id))) {
        this.listOfSelected.push(selectedCompetency);
      }
    },
    resetQuestionnaireStore(): void {
      this.questionnaires = {};
      this.currentquestionnaireId = "";
      this.activeSelectedCompetency = 0;
      this.listOfSelected = [];
      this.editQuestionnaireMode = false;
      this.previewState = false;
    }
  },
  persist: false
});
