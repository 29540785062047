<template>
  <div
    class="N700 flex justify-end content-center px-6"
    @mouseleave="removeDropdowns"
  >
    <div class="flex gap-4">
      <div
        class="
          self-center
          cursor-pointer
          bg-[#D8DAE5]
          p-2
          rounded-lg
          gap-2
          md:flex
          hidden
        "
        @click="showAppsDropdown = !showAppsDropdown"
      >
        <MenuSvg />
        <p class="N900 P200">Apps</p>
      </div>
      <div
        class="
          self-center
          cursor-pointer
          bg-[#D8DAE5]
          p-2
          rounded-lg
          gap-2
          md:flex
          hidden
        "
        @click="redirectToHelp"
      >
        <HelpSvg />
      </div>
      <div
        class="flex gap-4 cursor-pointer"
        @click="showProfiledropdown = !showProfiledropdown"
      >
        <ProfileImageComponent
          :initials="getUserNameInitials(userName)"
          :imgSrc="profilePicture"
          widthSize="2.5rem"
          heightSize="2.5rem"
          text-size="text-2l"
        />
        <p class="self-center N900 username">{{ store.user.first_name }}</p>

        <img
          src="@/assets/arrow-down.svg"
          class="cursor-pointer h-fit self-center"
          alt=""
        />
      </div>
      <ProfileDropdown v-if="showProfiledropdown" />
      <AppsNavigation v-if="showAppsDropdown" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { usePerformanceAccess } from "@/store/performance_access_store";
import ProfileDropdown from "./ProfileDropdown.vue";
import AppsNavigation from "./AppNavigation.vue";
import { getUserNameInitials } from "@/helpers/functions";
import ProfileImageComponent from "@/components/Avatar/ProfileImageComponent.vue";
import MenuSvg from "@/apps/performance-assessment/assets/MenuSvg.vue";
import HelpSvg from "@/apps/performance-assessment/assets/HelpSvg.vue";
import { useRouter } from "vue-router";

const store = usePerformanceAccess();
const route = useRouter();
const userName = computed(
  () => store.user.first_name + " " + store.user.last_name
);
const profilePicture = ref(store.user.profile_image);
const showProfiledropdown = ref<boolean>(false);
const showAppsDropdown = ref<boolean>(false);
const removeDropdowns = () => {
  showAppsDropdown.value = false;
  showProfiledropdown.value = false;
};

const redirectToHelp = () => {
  route.push("/performance-assisstance");
};
</script>
<style>
@media (max-width: 420px) {
  .username {
    display: none;
  }
}
</style>
