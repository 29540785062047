<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 24 24"
    fill="none"
    class="p-2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9392 2.42907L9.52922 8.03907H7.11922C6.71922 8.03907 6.32922 8.06907 5.94922 8.14907L6.94922 5.74907L6.98922 5.65907L7.04922 5.49907C7.07922 5.42907 7.09922 5.36907 7.12922 5.31907C8.28922 2.62907 9.58922 1.78907 11.9392 2.42907Z"
      fill="#DD5928"
    />
    <path
      d="M18.7291 8.30926L18.7091 8.29926C18.1091 8.12926 17.4991 8.03926 16.8791 8.03926H10.6191L12.8691 2.80926L12.8991 2.73926C13.0391 2.78926 13.1891 2.85926 13.3391 2.90926L15.5491 3.83926C16.7791 4.34926 17.6391 4.87926 18.1691 5.51926C18.2591 5.63926 18.3391 5.74926 18.4191 5.87926C18.5091 6.01926 18.5791 6.15926 18.6191 6.30926C18.6591 6.39926 18.6891 6.47926 18.7091 6.56926C18.8591 7.07926 18.8691 7.65926 18.7291 8.30926Z"
      fill="#DD5928"
    />
    <path
      d="M12.5195 17.8793H12.7695C13.0695 17.8793 13.3195 17.6093 13.3195 17.2793C13.3195 16.8593 13.1995 16.7993 12.9395 16.6993L12.5195 16.5493V17.8793Z"
      fill="#DD5928"
    />
    <path
      d="M18.2902 9.73906C17.8402 9.60906 17.3702 9.53906 16.8802 9.53906H7.12023C6.44023 9.53906 5.80023 9.66906 5.20023 9.92906C3.46023 10.6791 2.24023 12.4091 2.24023 14.4191V16.3691C2.24023 16.6091 2.26023 16.8391 2.29023 17.0791C2.51023 20.2591 4.21023 21.9591 7.39023 22.1691C7.62023 22.1991 7.85023 22.2191 8.10023 22.2191H15.9002C19.6002 22.2191 21.5502 20.4591 21.7402 16.9591C21.7502 16.7691 21.7602 16.5691 21.7602 16.3691V14.4191C21.7602 12.2091 20.2902 10.3491 18.2902 9.73906ZM13.2802 15.7191C13.7402 15.8791 14.3602 16.2191 14.3602 17.2791C14.3602 18.1891 13.6502 18.9191 12.7702 18.9191H12.5202V19.1391C12.5202 19.4291 12.2902 19.6591 12.0002 19.6591C11.7102 19.6591 11.4802 19.4291 11.4802 19.1391V18.9191H11.3902C10.4302 18.9191 9.64023 18.1091 9.64023 17.1091C9.64023 16.8191 9.87023 16.5891 10.1602 16.5891C10.4502 16.5891 10.6802 16.8191 10.6802 17.1091C10.6802 17.5291 11.0002 17.8791 11.3902 17.8791H11.4802V16.1891L10.7202 15.9191C10.2602 15.7591 9.64023 15.4191 9.64023 14.3591C9.64023 13.4491 10.3502 12.7191 11.2302 12.7191H11.4802V12.4991C11.4802 12.2091 11.7102 11.9791 12.0002 11.9791C12.2902 11.9791 12.5202 12.2091 12.5202 12.4991V12.7191H12.6102C13.5702 12.7191 14.3602 13.5291 14.3602 14.5291C14.3602 14.8191 14.1302 15.0491 13.8402 15.0491C13.5502 15.0491 13.3202 14.8191 13.3202 14.5291C13.3202 14.1091 13.0002 13.7591 12.6102 13.7591H12.5202V15.4491L13.2802 15.7191Z"
      fill="#DD5928"
    />
    <path
      d="M10.6797 14.3593C10.6797 14.7793 10.7997 14.8393 11.0597 14.9393L11.4797 15.0893V13.7593H11.2297C10.9197 13.7593 10.6797 14.0293 10.6797 14.3593Z"
      fill="#DD5928"
    />
  </svg>
</template>
