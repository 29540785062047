<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 24 24"
    fill="none"
    class="p-2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2.21924C6.49 2.21924 2 6.70924 2 12.2192C2 17.7292 6.49 22.2192 12 22.2192C17.51 22.2192 22 17.7292 22 12.2192C22 6.70924 17.51 2.21924 12 2.21924ZM16.35 15.7892C16.21 16.0292 15.96 16.1592 15.7 16.1592C15.57 16.1592 15.44 16.1292 15.32 16.0492L12.22 14.1992C11.45 13.7392 10.88 12.7292 10.88 11.8392V7.73924C10.88 7.32924 11.22 6.98924 11.63 6.98924C12.04 6.98924 12.38 7.32924 12.38 7.73924V11.8392C12.38 12.1992 12.68 12.7292 12.99 12.9092L16.09 14.7592C16.45 14.9692 16.57 15.4292 16.35 15.7892Z"
      fill="#DD5928"
    />
  </svg>
</template>
