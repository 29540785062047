<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 24 24"
    fill="none"
    class="p-2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.78 2.21924H8.22C4.44 2.21924 3.5 3.22924 3.5 7.25924V18.5192C3.5 21.1792 4.96 21.8092 6.73 19.9092L6.74 19.8992C7.56 19.0292 8.81 19.0992 9.52 20.0492L10.53 21.3992C11.34 22.4692 12.65 22.4692 13.46 21.3992L14.47 20.0492C15.19 19.0892 16.44 19.0192 17.26 19.8992C19.04 21.7992 20.49 21.1692 20.49 18.5092V7.25924C20.5 3.22924 19.56 2.21924 15.78 2.21924ZM7.78 12.2192C7.23 12.2192 6.78 11.7692 6.78 11.2192C6.78 10.6692 7.23 10.2192 7.78 10.2192C8.33 10.2192 8.78 10.6692 8.78 11.2192C8.78 11.7692 8.33 12.2192 7.78 12.2192ZM7.78 8.21924C7.23 8.21924 6.78 7.76924 6.78 7.21924C6.78 6.66924 7.23 6.21924 7.78 6.21924C8.33 6.21924 8.78 6.66924 8.78 7.21924C8.78 7.76924 8.33 8.21924 7.78 8.21924ZM16.23 11.9692H10.73C10.32 11.9692 9.98 11.6292 9.98 11.2192C9.98 10.8092 10.32 10.4692 10.73 10.4692H16.23C16.64 10.4692 16.98 10.8092 16.98 11.2192C16.98 11.6292 16.64 11.9692 16.23 11.9692ZM16.23 7.96924H10.73C10.32 7.96924 9.98 7.62924 9.98 7.21924C9.98 6.80924 10.32 6.46924 10.73 6.46924H16.23C16.64 6.46924 16.98 6.80924 16.98 7.21924C16.98 7.62924 16.64 7.96924 16.23 7.96924Z"
      fill="#DD5928"
    />
  </svg>
</template>
