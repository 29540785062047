<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 24 24"
    fill="none"
    class="p-2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7502 3.77924V2.21924C16.7502 1.80924 16.4102 1.46924 16.0002 1.46924C15.5902 1.46924 15.2502 1.80924 15.2502 2.21924V3.71924H8.75023V2.21924C8.75023 1.80924 8.41023 1.46924 8.00023 1.46924C7.59023 1.46924 7.25023 1.80924 7.25023 2.21924V3.77924C4.55023 4.02924 3.24023 5.63924 3.04023 8.02924C3.02023 8.31924 3.26023 8.55924 3.54023 8.55924H20.4602C20.7502 8.55924 20.9902 8.30924 20.9602 8.02924C20.7602 5.63924 19.4502 4.02924 16.7502 3.77924Z"
      fill="#DD5928"
    />
    <path
      d="M20 10.0591H4C3.45 10.0591 3 10.5091 3 11.0591V17.2191C3 20.2191 4.5 22.2191 8 22.2191H16C19.5 22.2191 21 20.2191 21 17.2191V11.0591C21 10.5091 20.55 10.0591 20 10.0591ZM14.84 15.2091L14.34 15.7191H14.33L11.3 18.7491C11.17 18.8791 10.9 19.0191 10.71 19.0391L9.36 19.2391C8.87 19.3091 8.53 18.9591 8.6 18.4791L8.79 17.1191C8.82 16.9291 8.95 16.6691 9.08 16.5291L12.12 13.4991L12.62 12.9891C12.95 12.6591 13.32 12.4191 13.72 12.4191C14.06 12.4191 14.43 12.5791 14.84 12.9891C15.74 13.8891 15.45 14.5991 14.84 15.2091Z"
      fill="#DD5928"
    />
  </svg>
</template>
