<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 24 24"
    fill="none"
    class="p-2"
  >
    <path
      d="M14.3498 2H9.64977C8.60977 2 7.75977 2.84 7.75977 3.88V4.82C7.75977 5.86 8.59977 6.7 9.63977 6.7H14.3498C15.3898 6.7 16.2298 5.86 16.2298 4.82V3.88C16.2398 2.84 15.3898 2 14.3498 2Z"
      fill="#DD5928"
    />
    <path
      d="M17.2391 4.82001C17.2391 6.41001 15.9391 7.71001 14.3491 7.71001H9.64906C8.05906 7.71001 6.75906 6.41001 6.75906 4.82001C6.75906 4.26001 6.15906 3.91001 5.65906 4.17001C4.24906 4.92001 3.28906 6.41001 3.28906 8.12001V17.53C3.28906 19.99 5.29906 22 7.75906 22H16.2391C18.6991 22 20.7091 19.99 20.7091 17.53V8.12001C20.7091 6.41001 19.7491 4.92001 18.3391 4.17001C17.8391 3.91001 17.2391 4.26001 17.2391 4.82001ZM15.3391 12.73L11.3391 16.73C11.1891 16.88 10.9991 16.95 10.8091 16.95C10.6191 16.95 10.4291 16.88 10.2791 16.73L8.77906 15.23C8.48906 14.94 8.48906 14.46 8.77906 14.17C9.06906 13.88 9.54906 13.88 9.83906 14.17L10.8091 15.14L14.2791 11.67C14.5691 11.38 15.0491 11.38 15.3391 11.67C15.6291 11.96 15.6291 12.44 15.3391 12.73Z"
      fill="#DD5928"
    />
  </svg>
</template>
