import PageNotFound from "../../PageNotFound.vue";

const authMeta = { auth: true };

const createRoute = (
  path: string,
  name: string,
  componentPath: string,
  view = true
) => ({
  path,
  name,
  meta: { auth: true },
  component: () =>
    view
      ? import(
          /* webpackChunkName: chunkName */ `../views/${componentPath}.vue`
        )
      : import(
          /* webpackChunkName: chunkName */ `../components/${componentPath}.vue`
        )
});

export default [
  createRoute(
    "/assessment-manager",
    "assessment-managers",
    "AssessmentManager"
  ),
  createRoute(
    "/create-questionnaire",
    "CreateQuestionnaire",
    "AddEditQuestionnaireView"
  ),
  createRoute(
    "/questionnaire-edit-mode",
    "questionnaire-edit-mode",
    "AddEditQuestionnaireView"
  ),

  {
    path: "/configurations",
    name: "configurations",
    meta: authMeta,
    component: () => import("../views/PA-Configs.vue"),
    children: [
      createRoute("assessment-cycle", "AssessmentCycle", "AssessmentCycle"),
      createRoute("timelines", "Timelines", "TimelinesView"),
      createRoute("assessment-type", "AssessmentType", "ConfigureAssessments"),
      createRoute("competency", "Competency", "CompetencyView"),
      createRoute(
        "assessment-manager",
        "AssessmentManager",
        "cdcCycle/CdcCyclye"
      ),
      createRoute("questionnaire", "Questionnaire", "QuestionnaireView"),
      {
        path: "",
        redirect: (to: unknown) => {
          return { path: "/configurations/access-level" };
        },
        meta: authMeta
      },

      createRoute("access-level", "AccessLevel", "AccessLevel/AccessLevel"),
      createRoute("peer-assignment", "PeerAssignment", "PeerAssignment")
    ]
  },
  createRoute(
    "/configurations/create-assessment-cycle",
    "CreateAssessmentCycle",
    "AddEditAssessmentCycle"
  ),
  createRoute(
    "/configurations/create-access-level",
    "CreateAccessLevel",
    "AccessLevel/CreateAccessLevel"
  ),
  createRoute(
    "/configurations/edit-access-level/:id",
    "EditAccessLevel",
    "AccessLevel/EditAccessLevel"
  ),
  createRoute("/notification", "NotificationView", "NotificationView"),

  createRoute(
    "/answer-questionnaire",
    "AnswerQuestionnaire",
    "AnswerQuestionnaireView"
  ),
  createRoute(
    "/configurations/create-assessment-type",
    "create-assessment-type",
    "CreateEditAssessmentType"
  ),
  createRoute(
    "/configurations/edit-assessment-type/:id",
    "edit-assessment-type",
    "CreateEditAssessmentType"
  ),
  {
    path: "/assessments",
    name: "allAssessments",
    meta: authMeta,
    component: () => import("../views/assessments/AllAssessments.vue"),
    children: [
      {
        path: "cdc-based-assessments",
        name: "cdc-based-assessments",
        meta: authMeta,
        component: () => import("../views/assessments/CdcAssessments.vue"),
        children: [
          createRoute(
            "",
            "self-assessment-dashboard",
            "assessments/SelfAssessmentDashboard"
          ),
          createRoute(
            "direct-report-assessment",
            "direct-report-assessment",
            "DirectReport"
          ),
          createRoute("peer-assessment", "peer-assessment", "PeerAssessment"),
          createRoute(
            "manager-assessment",
            "manager-assessment",
            "ManagerAssessment"
          ),
          createRoute(
            "client-assessment",
            "client-assessment",
            "assessments/ClientAssessment"
          )
        ]
      },
      createRoute(
        "/assessments/non-cdc-based-assessments",
        "NonCDCBasedAssessments",
        "assessments/NonCdcAssessment"
      ),
      {
        path: "",
        redirect: (to: unknown) => {
          return { path: "/assessments/cdc-based-assessments" };
        },
        meta: authMeta
      }
    ]
  },
  {
    path: "/assessment-details",
    name: "assessment-details",
    meta: authMeta,
    component: () =>
      import("../views/assessments/AssessmentOverviewAnalytics.vue"),
    children: [
      createRoute(
        "/assessment-details/analysis",
        "AssessmentAnalytics",
        "assessments/AssessmentAnalysis"
      ),
      createRoute(
        "/assessment-details/assessment-report",
        "AssessmentReport",
        "assessments/AssessmentReport"
      ),
      createRoute(
        "/assessment-details/cdc-decision",
        "CdcDecision",
        "assessments/CdcDecision"
      )
    ]
  },
  createRoute("/peer-assignment", "peer-assignment", "PeerAssignment"),
  createRoute(
    "/allocate-peer/:id",
    "allocate-peer",
    "PeerAssignmentAssets/AllocatePeer"
  ),
  createRoute(
    "/view-assessment-group",
    "view-assessment-group",
    "cdcDashboard/PerformanceAssessment",
    false
  ),

  createRoute(
    "/user-details/:userId/:id",
    "user-details",
    "cdcDashboard/detailEmployeeAssessment/UserDetails",
    false
  ),

  createRoute(
    "/assessments/manager-assessment/manager-response",
    "manager-assessment-response",
    "responses-components/managerAssessmentResponseComponent",
    false
  ),

  createRoute(
    "/assessments/cdc-based-assessments/peer-assessment/peer-response",
    "peer-response",
    "responses-components/peerAssessmentResponseComponent",
    false
  ),

  createRoute(
    "/assessments/direct-report-assessment/direct-response",
    "direct-response",
    "responses-components/directReportAssessmentResponseComponent",
    false
  ),

  createRoute(
    "/assessments/self-assessment",
    "self-assessment",
    "SelfAssessment"
  ),
  createRoute(
    "/assessments/:assessmentType/:id",
    "non-cdc-assessment",
    "StartNonCdcAssessment"
  ),
  createRoute(
    "/assessments/self-assessment-response",
    "self-response",
    "responses-components/selfAssessmentResponseComponent",
    false
  ),
  createRoute(
    "/assessments/non-cdc-assessment-response",
    "non-cdc-assessment-response",
    "responses-components/nonCdcResponseComponent",
    false
  ),

  createRoute(
    "/performance-assisstance",
    "performance-assisstance",
    "FAQs/HelpPage",
    false
  ),

  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    meta: authMeta,
    component: PageNotFound
  },
  {
    path: "/assessments/assessment-details",
    name: "assessment-details",
    meta: authMeta,
    component: () =>
      import("../views/assessments/AssessmentOverviewAnalytics.vue"),
    children: [
      createRoute(
        "/assessments/assessment-details/analysis",
        "AssessmentAnalytics",
        "assessments/AssessmentAnalysis"
      ),
      createRoute(
        "/assessments/assessment-details/assessment-report",
        "AssessmentReport",
        "assessments/AssessmentReport"
      ),
      createRoute(
        "/assessments/assessment-details/cdc-decision",
        "CdcDecision",
        "assessments/CdcDecision"
      )
    ]
  }
];
