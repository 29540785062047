import { defineStore } from "pinia";
import {
  ResponseType,
  ResponseSetType,
  JustificationSetType
} from "../helpers/employeeAssessmentTypes";

// Responses Store
// To assessment responses to the various assemssments
// To keep user selected values both linear and short paragraph answer types
export const useResponsesStore = defineStore({
  id: "responses_store",
  state: () => ({
    responses: {} as ResponseSetType,
    justifications: {} as JustificationSetType,
    questionIDs: [] as Array<number>,
    responsesCompetencyNames: [] as Array<{
      id: number;
      competency_name: string;
      criteria: string;
      competency_status: boolean;
      justify_minimum_value: string;
      justify_maximum_value: string;
    }>,
    completedAssessmentDialogState: false as boolean,
    summary_comment: "",
    promotion_type: ""
  }),
  getters: {},
  actions: {
    addResponse(responsesObject: ResponseType) {
      // set a competency id of zero to non-competency-based questionnaires (As their competency ids are set to null)
      if (responsesObject.competency_id === null) {
        responsesObject.competency_id = 0;
      }
      if (
        !Object.keys(this.responses).includes(
          String(responsesObject.competency_id)
        )
      ) {
        this.responses[`${responsesObject.competency_id}`] = [];
        if (!this.questionIDs.includes(responsesObject.question_id)) {
          this.responses[`${responsesObject.competency_id}`].push(
            responsesObject
          );
          this.questionIDs.push(responsesObject.question_id);
        }
      }
      if (!this.questionIDs.includes(responsesObject.question_id)) {
        this.responses[`${responsesObject.competency_id}`].push(
          responsesObject
        );
        this.questionIDs.push(responsesObject.question_id);
      }
    },
    addResponseAndJustification(
      responses: ResponseSetType,
      justifications: JustificationSetType
    ) {
      this.responses = responses;
      this.justifications = justifications;
    },
    closeCompletionDialog() {
      this.completedAssessmentDialogState = false;
    }
  },
  persist: true
});
