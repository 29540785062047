// Add a generator class to handle generation of the random id
export class Generator {
  // Add generator function for a four digit random number
  static randomNumber(min: number, max: number) {
    const cryptoArray = new Uint32Array(1);
    window.crypto.getRandomValues(cryptoArray);
    const cryptoRandom = cryptoArray[0] / 2 ** 32;
    return Math.floor(cryptoRandom * (max - min + 1) + min);
  }

  static getRandomId(type: string) {
    const date = new Date();
    const randomNumber = this.randomNumber(1000, 10000);
    // each id is a combination of the type, date, and a random number
    return `${type.toUpperCase()}-${date
      .toISOString()
      .slice(0, 10)}-${randomNumber}`;
  }
}

export interface QuestionType {
  id?: string;
  question_id: string;
  question: string;
  required_field: boolean;
  options_type: string;
  minimum_score: string;
  maximum_score: string;
  questionnaire_id: string;
  competency_id: number;
  questionnaire_group_id: number;
}

export interface QuestionnaireType {
  id: string;
  createdDate: Date;
  isCompetencyBased: boolean;
  questions: { [key: string]: QuestionType[] };
  title: string;
  description: string;
}

// Each form instance to contain instances of the question class and the responses class
export class Questionnaire {
  id: string;
  createdDate: Date;
  isCompetencyBased: boolean;
  // Each questionnaire to contain an array of question instances or objects
  questions: { [key: string]: QuestionType[] };
  title: string;
  description: string;

  constructor() {
    // Each form instance would have an auto generated id
    this.id = Generator.getRandomId("questionnaire");
    this.createdDate = new Date();
    this.isCompetencyBased = false;
    this.title = "";
    this.description = "";
    this.questions = {} as { [key: string]: QuestionType[] };
  }
}

// Each form instance to contain a given question with its corresponding set of options
export class Question {
  question_id: string;
  question: string;
  // options: unknown; //Comment out the options property for now as it is not being used at the moment
  required_field: boolean;
  options_type: string;
  minimum_score: string;
  maximum_score: string;
  // The questionnaire the question can be found in
  questionnaire_id: string;
  // The competency the question belongs to
  competency_id: number;
  questionnaire_group_id: number;
  constructor(
    questionnaire_id: string,
    questionObj: {
      updateId?: string;
      question: string;
      optionsObject: { options_type: string; options?: string };
      required_field?: boolean;
    }
  ) {
    const {
      question = "Untitled question",
      optionsObject,
      required_field = false,
      updateId
    } = questionObj;
    // Questionnaire ID represents the id of the questionnaire the question belongs
    this.questionnaire_id = questionnaire_id;
    // The competency the question belongs to
    this.competency_id = 0;
    //generated main id to identify each unique question
    // Update id to be used in the case of a duplicate question
    this.question_id = updateId ?? Generator.getRandomId("question");
    this.question = question;
    // options currently storing a string; ie: either a short answer or a linear (range)
    // "options" used to allow addition of other input options with time
    // such as a multiple select or choice question
    this.required_field = required_field;
    this.options_type = optionsObject.options_type;
    // add the minimum and maximum scores
    this.minimum_score = "1";
    this.maximum_score = "10";
    this.questionnaire_group_id = 0;
  }
}
// Each response instance to contain the response from a user for a given form instance
export class Response {
  question_id: number;
  competency_id: number;
  required_field: boolean;
  response_data: string;
  options_type: string;
  constructor(responseObject: {
    qtid: number;
    competency_id: number;
    required_field: boolean;
    response_data: string;
    options_type: string;
  }) {
    // the question id is the qtid; //id of the question the response is for
    this.question_id = responseObject.qtid;
    this.competency_id = responseObject.competency_id;
    this.required_field = responseObject.required_field;
    // The response data is the object containing all the answers to all the questions from a user
    this.response_data = responseObject.response_data;
    this.options_type = responseObject.options_type;
  }
}
export class Header {
  title: string;
  description: string;
  constructor(title = "Untitled Title") {
    this.title = title;
    this.description = "Description (optional)";
  }
}
