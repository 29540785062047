import { UserType } from "@/apps/authentication/types/AuthTypes";
import { defineStore } from "pinia";

export const useStore = defineStore({
  id: "login_data",
  state: () => ({
    email: "",
    id: 0,
    manager: 0,
    adviser: [] as Array<number>,
    url: "",
    userName: "",
    allUsers: [] as Array<object>,
    user: {} as UserType,
    profilePicture: "",
    empInfoUsers: [] as Array<object>,
    permissions: {},
    currentStep: null,
    totalStep: null,
    onboardingPending: false as boolean,
    userInfo: {} as UserType,
    cdcAdvisor: "" as string | number,
    loanuser: 0,
    loginHint: "",
    showAll: false
  }),
  getters: {},
  actions: {
    setLoanUser(data: number) {
      this.loanuser = data;
    },
    setEmail(email: string) {
      this.email = email;
    },
    setId(id: number) {
      this.id = id;
    },
    setManager(id: number) {
      this.manager = id;
    },
    setAdviser(ids: Array<number>) {
      this.adviser = ids;
    },
    setURL(url: string) {
      this.url = url;
    },
    setName(name: string) {
      this.userName = name;
    },
    setAllUsers(data: Array<object>) {
      this.allUsers = data;
    },
    setUser(data: UserType) {
      this.user = data;
    },
    setProfilePicture(url: string) {
      this.profilePicture = url;
    },
    setEmpInfoUsers(data: Array<object>) {
      this.empInfoUsers = data;
    },
    setPermission(data: object) {
      this.permissions = data;
    },
    setOnboardingStatus(data: boolean) {
      this.onboardingPending = data;
    },
    setUserInfo(data: UserType) {
      this.userInfo = data;
    },
    setCdcAdvisor(data: string | number) {
      this.cdcAdvisor = data;
    },
    setLoginHint(data: string) {
      this.loginHint = data;
    }
  },
  persist: true
});
