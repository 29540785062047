import { defineStore } from "pinia";
import { PermissionType } from "@/apps/performance-assessment/types/performanceAssessmentStoreTypes";
import { Decrypt, Encrypt } from "@/services/crypto";
import { UserType } from "@/apps/authentication/types/AuthTypes";

type Permission = {
  permission_name: string;
  hide: boolean;
  edit: boolean;
  view: boolean;
};

type Permissions = Permission[];
export const usePerformanceAccess = defineStore({
  id: "assignPerformanceAccessLevels",
  state: () => ({
    user: {} as UserType,
    loginHint: "",
    url: "",
    permissions: [] as Permissions,
    onboardingPending: false as boolean,
    cdcAdvisor: "" as string | number,
    showAll: false,
    accessSet: false,
    settings: false
  }),
  getters: {},
  actions: {
    setURL(url: string) {
      this.url = url;
    },
    setPermissions(permissions: PermissionType) {
      if (Array.isArray(permissions)) {
        this.permissions.push(...permissions);
      } else {
        this.permissions.push(permissions);
      }
    },
    setUser(data: UserType) {
      this.user = data;
    },
    setOnboardingStatus(data: boolean) {
      this.onboardingPending = data;
    },
    setCdcAdvisor(data: string | number) {
      this.cdcAdvisor = data;
    },
    setLoginHint(data: string) {
      this.loginHint = data;
    },
    setAccessSet(access: boolean) {
      this.accessSet = access;
    }
  },
  persist: {
    serializer: {
      deserialize: (value) => JSON.parse(Decrypt(JSON.parse(value))),
      serialize: (value) => JSON.stringify(Encrypt(JSON.stringify(value)))
    }
  }
});
