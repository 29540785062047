<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M4.52861 7.35311C6.088 7.35311 7.35214 6.08898 7.35214 4.52958C7.35214 2.97019 6.088 1.70605 4.52861 1.70605C2.96922 1.70605 1.70508 2.97019 1.70508 4.52958C1.70508 6.08898 2.96922 7.35311 4.52861 7.35311Z"
      stroke="#101840"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.2934 7.35311H13.7052C15.117 7.35311 15.8228 6.64723 15.8228 5.23547V3.8237C15.8228 2.41194 15.117 1.70605 13.7052 1.70605H12.2934C10.8817 1.70605 10.1758 2.41194 10.1758 3.8237V5.23547C10.1758 6.64723 10.8817 7.35311 12.2934 7.35311Z"
      stroke="#101840"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.47 7.35311C23.0294 7.35311 24.2935 6.08898 24.2935 4.52958C24.2935 2.97019 23.0294 1.70605 21.47 1.70605C19.9106 1.70605 18.6465 2.97019 18.6465 4.52958C18.6465 6.08898 19.9106 7.35311 21.47 7.35311Z"
      stroke="#101840"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.82273 15.8233H5.23449C6.64625 15.8233 7.35214 15.1174 7.35214 13.7057V12.2939C7.35214 10.8822 6.64625 10.1763 5.23449 10.1763H3.82273C2.41096 10.1763 1.70508 10.8822 1.70508 12.2939V13.7057C1.70508 15.1174 2.41096 15.8233 3.82273 15.8233Z"
      stroke="#101840"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.9993 15.8233C14.5587 15.8233 15.8228 14.5592 15.8228 12.9998C15.8228 11.4404 14.5587 10.1763 12.9993 10.1763C11.4399 10.1763 10.1758 11.4404 10.1758 12.9998C10.1758 14.5592 11.4399 15.8233 12.9993 15.8233Z"
      stroke="#101840"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.7641 15.8233H22.1759C23.5877 15.8233 24.2935 15.1174 24.2935 13.7057V12.2939C24.2935 10.8822 23.5877 10.1763 22.1759 10.1763H20.7641C19.3524 10.1763 18.6465 10.8822 18.6465 12.2939V13.7057C18.6465 15.1174 19.3524 15.8233 20.7641 15.8233Z"
      stroke="#101840"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.52861 24.294C6.088 24.294 7.35214 23.0299 7.35214 21.4705C7.35214 19.9111 6.088 18.647 4.52861 18.647C2.96922 18.647 1.70508 19.9111 1.70508 21.4705C1.70508 23.0299 2.96922 24.294 4.52861 24.294Z"
      stroke="#101840"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.2934 24.294H13.7052C15.117 24.294 15.8228 23.5881 15.8228 22.1764V20.7646C15.8228 19.3529 15.117 18.647 13.7052 18.647H12.2934C10.8817 18.647 10.1758 19.3529 10.1758 20.7646V22.1764C10.1758 23.5881 10.8817 24.294 12.2934 24.294Z"
      stroke="#101840"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.47 24.294C23.0294 24.294 24.2935 23.0299 24.2935 21.4705C24.2935 19.9111 23.0294 18.647 21.47 18.647C19.9106 18.647 18.6465 19.9111 18.6465 21.4705C18.6465 23.0299 19.9106 24.294 21.47 24.294Z"
      stroke="#101840"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
