<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 24 24"
    fill="none"
    class="p-2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 21.25H20.75V11C20.75 8.58001 19.42 7.25001 17 7.25001H12.75V6.02001C13.33 6.16001 13.91 6.24001 14.5 6.24001C15.44 6.24001 16.38 6.06001 17.28 5.70001C17.56 5.59 17.75 5.31001 17.75 5.00001V2.00001C17.75 1.75001 17.63 1.52001 17.42 1.38001C17.21 1.24001 16.95 1.21001 16.72 1.30001C15.29 1.87001 13.71 1.87001 12.28 1.30001C12.05 1.21001 11.79 1.24001 11.58 1.38001C11.37 1.52001 11.25 1.75001 11.25 2.00001V5.00001V7.25001H7C4.58 7.25001 3.25 8.58001 3.25 11V21.25H2C1.59 21.25 1.25 21.59 1.25 22C1.25 22.41 1.59 22.75 2 22.75H4H20H22C22.41 22.75 22.75 22.41 22.75 22C22.75 21.59 22.41 21.25 22 21.25ZM7.24 21.25H4.75V12.75H7.24V21.25ZM11.24 21.25H8.74V12.75H11.24V21.25ZM15.24 21.25H12.74V12.75H15.24V21.25ZM19.25 21.25H16.74V12.75H19.25V21.25Z"
      fill="#DD5928"
    />
  </svg>
</template>
